// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
footer .nav>li {
  display: inline-block;
}
footer .nav>li>a:focus, .nav>li>a:hover {
  background-color: transparent;
}
footer .nav>li>a {
  position: relative;
  display: block;
  font-size: 17px;
  color: $ocean-blue;
  font-weight: 600;
  padding: 40px 20px 30px;
}
footer .nav>li>a:hover {
  color: $ocean-blue;
}
footer .icon-beard {
	width: 50px;
  height: 50px;
  margin-bottom: 20px;
  border-radius: 30px;
  opacity: 0.3;
  display: inline-block;
  border: 2px solid #999;
  transition: opacity .5s ease, border .5s ease;
}
footer .icon-beard:hover {
	cursor: pointer;
	border: 2px solid $ocean-blue;
	opacity: 1;
}