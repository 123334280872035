// -----------------------------------------------------------------------------
// This file contains styles that are specific to the Product details page.
// -----------------------------------------------------------------------------

.details-page {
	-webkit-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
  -moz-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
  box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
  padding: 3% 0;
  margin-top: 0;
  .thumbnail {
      padding: 0 15px;
  }
}
.details-page img {
	width: 100%;
}
.breadcrumb {
  background-color: transparent;
  li {
    text-transform: capitalize;
  }
}
.quantity {
	padding: 20px;
}
.quantity p {
	font-size: 20px;
}
.quantity input {
	width: 43px;
	padding: 10px;
}
.btn-order {
  display: block;
  background-color: #990000;
  border-radius: 0;
  font-size: 20px;
  color: #fff;
  width: 70%;
  margin: 0 auto;
  transition: background-color .5s ease;
}
.btn-order:hover {
	color: #fff;
	background-color: rgba(153, 0, 0, 0.8);
}
.right-col {
	color: $main-text;
}
.right-col h1 {
	font-size: 40px;
	margin-top: 0;
}
#first-p {
	font-size: 16px;
	letter-spacing: 0.1px;
}