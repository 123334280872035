// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.info-bar {
    position: relative;
    background-color: $ocean-blue;
    height: 54px;
    font-size: 18px;
    .container {
        position: relative;
        top: 17px;
    }
    i { 
        color: #fff; 
        padding-right: 10px;
    }
    .number {
        color: #fff;
    }
}