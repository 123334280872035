// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}
.page-wrap {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -251px; 
}
.page-wrap:after {
  content: "";
  display: block;
}
.site-footer, .page-wrap:after {
  /* .push must be the same height as footer */
  height: 251px; 
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
} 

/**
 * Basic styles for links
 */
a {
  color: $text-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: none;
  }
}

/**
 * Bootstrap overrides 
 */
.thumbnail .caption {
  color: $text-color;
}

/*.container {
  max-width: $max-width;
  width: 100%;
  padding: 0 20px;
}*/

.color-stripe {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: #990000;
  .stripe-block {
    height: 40px;
    width: 40px;
    margin: 0px auto;
    background-color: #990000;
    -webkit-clip-path: polygon(0 0, 50% 50%, 100% 0);
    clip-path: polygon(0 0, 50% 50%, 100% 0);
    position: absolute;
    top: 18px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0);
  }
}
.signin {
  padding: 5%;
}

