// -----------------------------------------------------------------------------
// This file contains styles that are specific to the about page.
// -----------------------------------------------------------------------------

.about-page {
	-webkit-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
  -moz-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
  box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
  text-align: center;
  padding: 5% 15px;
}
.light-grey-bg {
	background-color: #eee;
	padding-right: 0;
	padding-left: 0;
}
.about-page h3 {
	font-size: 40px;
  font-weight: 600;
  color: $ocean-blue;
}
.about-page p {
  font-size: 18px;
  color: $text-color;
  font-weight: 400;
}
.about-page h4 {
	font-size: 25px;
  font-weight: 600;
  color: $ocean-blue;
}
.about-page img,
.about-page h4 {
	display: inline-block;
}
.about-page img {
  width: 50px;
  margin-right: 10px;
}
.padding-5 {
	padding: 5% 0 0;
}