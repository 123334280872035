// -----------------------------------------------------------------------------
// This file contains styles that are specific to the category page.
// -----------------------------------------------------------------------------


.category-page,
.details-page,
.service-support-page {
	margin-top: 5%;
	.thumbnail {
		text-align: center;
		border: none;
	}
	.detail-wrapper {
		position: relative;
	}
	.detail-wrapper img {
		position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 100%;
    z-index: 0;
	}
	.details {
		position: absolute;
		opacity: 0;
	  background-color: rgba(68,110,146,0.50);
	  width: 100%;
	  height: 100%;
	  z-index: 1;
	  transition: opacity .5s ease;
	}
	.details p {
	  background-color: #446e92;
	  padding: 10px 15px;
	  color: #fff;
	  font-size: 25px;
	  font-weight: 600;
	  letter-spacing: 2px;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  margin-right: -50%;
	  transform: translate(-50%, -50%);
	}
	.detail-wrapper:hover .details,
	.detail-wrapper:active .details {
		opacity: 1;
	}
}
.category-tabs {
	background-color: #eee;
}
.category-stripe {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: #990000;
}

.category-stripe-block {
	position: absolute;
  height: 40px;
  width: 40px;
  background-color: #990000;
  -webkit-clip-path: polygon(0 0, 50% 50%, 100% 0);
  clip-path: polygon(0 0, 50% 50%, 100% 0);
  top: 145px; // 249px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 768px) {
	.category-stripe-block { top:249px; }
}



