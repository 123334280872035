// -----------------------------------------------------------------------------
// This file contains all styles related to the main navigation component.
// -----------------------------------------------------------------------------

#myModal .btn-upload {
  margin-right: 15px;
}

#myModal .modal-body .modal-thumbnail {
  display: inline-block;
  background-color: #000;
  width: 20%;
  margin: 2%;
  input {
    width: 0;
    opacity: 0;
    position: absolute;
  }
  img {
    width: 100%;
  }
  &:hover {
    cursor: pointer;
  }
}
#myModal .modal-body .checked {
  position: relative;
  img {
    opacity: 0.5;
    transition: opacity .5s ease;
  }
}
#myModal .modal-body .checked::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "\2713 ";
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: white;
}