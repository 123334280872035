// -----------------------------------------------------------------------------
// This file contains all styles related to the admin navigation component.
// -----------------------------------------------------------------------------

.nav-admin {
    background-color: #444;
    color: white;
    width: 100%;
    position: relative;
    top: 0;
    ul {
        list-style: none;
        padding: 0;
        li {
            display: inline-block;
            padding: 10px;
        }
    }
    a {
        color: inherit;
    }
}