// -----------------------------------------------------------------------------
// This file contains all styles related to the main navigation component.
// -----------------------------------------------------------------------------
.navbar-nav > li:nth-child(0) a {
    padding-top:4px !important; 
    padding-bottom:0 !important;
    height: 100px;
}

.navbar-brand {
    height: 100px;
    width: 60%;
    position: relative;
    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }
}
.navbar {
    min-height:100px !important;
    background-color: $nav-bg;
    border-radius: 0;
    border: none;
}

.navbar-nav > li {
    border-bottom: #fff 6px solid;
}

.navbar-nav > .active {
    border-bottom: $burnt-red 6px solid;
    a { background-color: $nav-bg !important; }
}

.navbar-brand img {
    width: 100%;
    max-width: 200px;
}

.navbar {
    margin-bottom: 0;
}

// Nav button
.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: white;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: $ocean-blue;
}
.navbar-default .navbar-toggle {
    border-color: #fff;
}
.cart-custom-two {
    display: none !important;
}

@media screen and (min-width: map-get($breakpoints, 'nav-collapse')) {
    .navbar-brand { width: 100%; }
    .navbar-nav {
        height: 100%;
        li {
            height: 100%;
            float: none;
            display: inline-block;
            position: relative;
            a {
                height: 100%;
                .nav-text {
                    color: $dark-grey;
                    font-weight: 400;
                    position: relative;
                    top: 50%;
                    font-size: 16px;
                }
            }
        }
        li.active {
            border-bottom: $burnt-red 6px solid;
        }
    }
    .navbar-nav > li {
        height: 100%;
        float: none;
        display: inline-block;
    }
    .navbar-header {
        width: 25%;
    }
    .navbar.navbar-default {
        height: 100px;
        .container {
            height: 100%;
            position: relative;
        }
        .nav.navbar-nav {
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);
        }
    }
    .cart-custom {
        display: none;
    }
    .cart-custom-two {
        display: inline-block !important;
    }
}

@media (max-width: map-get($breakpoints, 'nav-collapse')) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
}