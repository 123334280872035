// -----------------------------------------------------------------------------
// This file contains all styles related to the carousel component.
// -----------------------------------------------------------------------------

.owl-carousel {
    position: relative;
    p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-weight: 700;
        font-size: 24px;
        text-align: center;
    }
}
.owl-dots {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #fff;
}
.owl-theme .owl-dots .owl-dot span {
    background: transparent;
    border: solid #fff 2px;
    width: 13px;
    height: 13px;
    margin: 5px 3px;
}