// -----------------------------------------------------------------------------
// This file contains styles that are specific to the contact page.
// -----------------------------------------------------------------------------

.contact-page {
	-webkit-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
  -moz-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
  box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
  text-align: center;
  padding: 5% 0;
}
.contact-page h3 {
	font-size: 40px;
  font-weight: 600;
  color: $ocean-blue;
  margin-bottom: 40px;
}
.contact-page p {
	font-size: 20px;
  color: $text-color;
  font-weight: 100;
  text-align: left;
}

#contact-page-form input,
#contact-page-form textarea {
	width: 100%;
	margin: 5px 0;
	padding: 8px;
	font-size: 20px;
	border: solid 2px;
}
#contact-page-form ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #b1bfcd;
  text-align: center;
}
#contact-page-form ::-moz-placeholder { /* Firefox 19+ */
  color: #b1bfcd;
  text-align: center;
}
#contact-page-form :-ms-input-placeholder { /* IE 10+ */
  color: #b1bfcd;
  text-align: center;
}
#contact-page-form :-moz-placeholder { /* Firefox 18- */
  color: #b1bfcd;
  text-align: center;
}






