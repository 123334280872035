.service-page {
  text-align: center;
	padding-left: 0;
	padding-right: 0;
	background-color: #eeeeee;
}
.service-heading,
.service-details {
	-webkit-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
  -moz-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
  box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
  text-align: center;
}
.service-heading h1 {
  font-size: 40px;
  padding-top: 30px;
  padding-bottom: 10px;
  color: $main-text;
}
.service-details {
  padding: 5% 0;
}
.service-details h1 {
  font-size: 40px;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 10px;
  text-align: left;
  color: $main-text;
}
.service-details input,
.service-details textarea {
  width: 100%;
  padding: 10px;
  margin: 7px 0;
  border: solid 2px #3c6181;
  font-size: 16px;
  text-align: center;
}

.service-details img { width: 100%; }