// -----------------------------------------------------------------------------
// This file contains all styles related to the category navigation component.
// -----------------------------------------------------------------------------

.nav-category {
    height: 70px;
    ul {
        list-style: none;
        padding-left: 0;
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
    }
    li {
        position: relative;
        text-align: center;
        padding: 4px;
        height: 100%;
        flex-grow: 1;
        a {
            color: $ocean-blue;
            font-weight: 700;
            font-size: 1.5em;
            width: 100%;
            img {
                width: auto;
                height: 100%;
            }
            .category-nav-text {
                display: none;
            }
        }
    }
}
.products-section,
.header,
.feature {
    padding: 60px 0;
    text-align: center;
    h2 {
        font-size: 40px;
        font-weight: 600;
        color: $main-text;
    }
    p {
        color: $main-text;
    }
}
.feature img {
    margin: 20px 0;
}
.header {
    -webkit-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
    -moz-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
    box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
}

@media screen and (min-width: map-get($breakpoints, 'medium')) {
    .nav-category {
        height: 170px;
        ul {
            li a .category-nav-text {
                display: block;
            }
        }
    }
}