// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.contact-btn {
  background: #990000;
  color: #fff;
  margin: 0 auto;
  font-size: 16px;
  border: none;
  border-radius: 0;
  transition: background .5s ease;
}
.contact-btn:hover {
	color: #fff;
  background: rgba(153, 0, 0, 0.69);
}

.confirm-btn {
  padding: 7px 20px;
  background: #990000;
  color: #fff;
  margin: 0 auto;
  font-size: 16px;
  border: none!important;
  border-radius: 0;
  transition: background .5s ease;
}
.confirm-btn:hover {
  color: #fff;
  background: rgba(153, 0, 0, 0.69);
}

.badge {
	color: #fff!important;
}