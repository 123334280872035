// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.blue-bg {
	background-color: #2a4359;
	padding-left: 0;
	padding-right: 0;
}
.grey-bg {
	background-color: #eee;
}
.max-w-400 {
	max-width: 400px;
}
.max-w-700 {
	max-width: 700px;
}
.max-w-900 {
	max-width: 900px;
}
.padding-50 {
	padding: 60px 0;
}

/* Product Section */
.main-product-blurb {
  font-size: 23px;
  padding: 5px;
}

/* Video Section */
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Feedback Section */
.video-section h3,
.feedback h3 {
	font-size: 40px;
  font-weight: 600;
  color: $ocean-blue;
}
.video-section p,
.feedback p {
  font-size: 18px;
  color: $text-color;
  font-weight: 100;
}
.feedback form {
	text-align: center;
}
.feedback form input,
.feedback form textarea {
	width: 70%;
	margin: 5px 0;
  padding: 5px;
  border: solid 2px #000;
}

/* Contact Section */

.contact-info {
  background: $ocean-blue;
  margin: 35px auto;
  padding: 0;
  width: 290px;
  .row {margin: 15px 0;}
  .info-text {
    color: #fff; 
    margin: 0 5px;
    font-weight: 400;
  }
  a.info-text {color: #fff;}
  img {
    border: solid 2px #fff; 
    padding: 5px 10px;
    width: 43px;
  }
  .icon-mail {padding:9px 5px;}
  .icon-location {height:40px; padding:3px 11px;}
  .text-phone-numbers {margin-top:7px;}
  .text-email {margin-top:7px};
  .text-location {margin-top:-7px;}
}
/* Centering reCapatcha */
.capatcha {
  text-align: center;
}
.g-recaptcha {
  display: inline-block;
}

.rohs {
	background-color: $ocean-blue;
	padding-left: 0;
	padding-right: 0;
}
.rohs img {
  display: block;
  margin: 10px auto;
}






