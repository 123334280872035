// -----------------------------------------------------------------------------
// This file contains styles that are specific to the cart page and checkout page.
// -----------------------------------------------------------------------------
.cart-page,
.checkout {
	padding: 5% 0;
	-webkit-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
	-moz-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
	box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.2);
}
.mar-right {
	margin-right: 20px;
}
table { 
	width: 100%; 
	border-collapse: collapse; 
}
tr:nth-of-type(odd) { 
  background: #eee; 
}
th { 
  background: #3c6181; 
  color: white; 
  font-weight: bold; 
}
td, th { 
  padding: 6px; 
  border: 1px solid #ccc; 
  text-align: left; 
}
@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {
  table, thead, tbody, th, td, tr { 
    display: block; 
  }
  thead tr { 
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  tr { border: 1px solid #ccc; }
  td { 
    border: none;
    border-bottom: 1px solid #eee; 
    position: relative;
    padding-left: 50%; 
  }
  
  td:before { 
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%; 
    padding-right: 10px; 
    white-space: nowrap;
  }
  td:nth-of-type(1):before { content: "Product Name:"; }
  td:nth-of-type(2):before { content: "Price:"; }
  td:nth-of-type(3):before { content: "Qty:"; }
  td:nth-of-type(4):before { content: "Item Total:"; }
  td:nth-of-type(5):before { content: "Add/Remove:"; }
}

.mar-top {
	margin-top: 20px;
}
/* Checkout Page */
.checkout h1 {
	font-size: 40px;
	margin-top: 0;
	margin-bottom: 0;
	color: #446e92;
}
.checkout h4,
.checkout p {
	color: #446e92;
  margin-left: 13px;
  margin-right: 13px;
}
.left-mar {
	margin-left: 0!important;
}
.checkout input,
.checkout select {
  border: solid 1px #446e92;
  border-radius: 0px;
}
#shipping-state-province,
#billing-state-province {
	-webkit-appearance: none;
}
input[type="radio"] {
	margin-right: 5px;
}
.checkout .btn {
  display: block;
  margin: 3% auto 0;
}
.cart-custom {
  position: absolute;
  right: 25px;
  top: 115px;
}

@media (min-width: 768px) {
	.checkout h4 {
	  margin-left: 0;
	}
	.left-mar {
		margin-left: -20px!important;
	}
}






